import * as React from 'react'
import * as UnderlineStyles from './Underline.module.css'

function Underline({ children }) {
    return (
        <div className={UnderlineStyles.container}>
            {children}
            <div className={UnderlineStyles.underline}></div>
        </div>
    )
}

export default Underline