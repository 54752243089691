import * as React from 'react'
import * as TestimonialStyles from './Testimonial.module.css'

function Testimonial({ children, name, age }) {
    return (
        <section className={TestimonialStyles.section}>
            <div className={TestimonialStyles.container}>
                <h3>Patient feedback</h3>
                <div className={TestimonialStyles.quote}>{children}</div>
                <div className={TestimonialStyles.reviewer}>&#8212; {name} <span className={TestimonialStyles.age}>({age})</span></div>
            </div>
        </section>
    )
}

export default Testimonial