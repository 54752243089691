import * as React from 'react'
import { Link } from 'gatsby'
import * as LinkGridStyles from './LinkGrid.module.css'

function GridItem(props) {
    return (
        <Link className={LinkGridStyles.item} to={props.href}>{props.name}<span className={LinkGridStyles.icon}>&#8594;</span></Link>
    )
}

function LinkGrid(props) {
    return (
        <div className={LinkGridStyles.container}>
            {
                props.items.map(item => (
                    <GridItem
                        key={item.id}
                        name={item.name}
                        href={item.path}
                    />
                ))
            }
        </div>
    )
}

export default LinkGrid