import * as React from 'react'
import * as BannerStyles from './Banner.module.css'

function Banner({ children }) {
    return (
            <div className={BannerStyles.container}>
                {children}
            </div>
    )
}

export default Banner