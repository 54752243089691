import * as React from 'react'

export const conditions = [{
    id: 0,
    name: 'Discomfort in advancing years',
    path: '/conditions-we-treat/discomfort-in-advancing-years/',
}, {
    id: 1,
    name: 'Pregnancy related discomfort',
    path: '/conditions-we-treat/pregnancy-related-discomfort/',
}, {
    id: 2,
    name: 'Postural strain at work & driving',
    path: '/conditions-we-treat/postural-strain-at-work-and-driving/',
}, {
    id: 3,
    name: 'Sports injury',
    path: '/conditions-we-treat/sports-injury/',
}, {
    id: 4,
    name: 'Neck & head pain',
    path: '/conditions-we-treat/neck-and-head-pain/',
}, {
    id: 5,
    name: 'Shoulder pain',
    path: '/conditions-we-treat/shoulder-pain/',
}, {
    id: 6,
    name: 'Knee pain',
    path: '/conditions-we-treat/knee-pain/',
}, {
    id: 7,
    name: 'Back pain',
    path: '/conditions-we-treat/back-pain/',
}, {
    id: 8,
    name: 'Pain in childhood & adolescence',
    path: '/conditions-we-treat/pain-in-childhood-and-adolescence/',
}, {
    id: 9,
    name: 'Ankle & foot pain',
    path: '/conditions-we-treat/ankle-and-foot-pain/',
}, ]

export const fees = {
    osteopathy: [{
        id: 0,
        service: 'Initial consultation & treatment (45-60 mins)',
        price: '£60',
    }, {
        id: 1,
        service: 'Follow-up treatment (30 mins)',
        price: '£55',
    }, /*{
        id: 2,
        service: 'Under 16s consultation & treatment',
        price: '£42.00',
    },*/ {
        id: 3,
        service: 'Home visits (by prior appointment only)',
        price: '£120'
    }],
    massage: [{
        id: 0,
        service: '45 mins sports / remedial massage',
        price: '£55',
    }],
    cranial: [{
        id: 0,
        service: 'Initial consultation & treatment (45-60 mins)',
        price: '£60',
    }, {
        id: 1,
        service: 'Follow-up treatment (30 mins)',
        price: '£55',
    }, /*{
        id: 2,
        service: 'Under 16s consultation & treatment',
        price: '£42.00',
    },*/
    ],
    infantCranial: [{
        id: 0,
        service: 'Initial consultation & treatment (90 mins)',
        price: '£60',
    }, {
        id: 1,
        service: 'Follow-up treatment (45 mins)',
        price: '£55'
    }, {
        id: 2,
        service: 'Home visits (by prior appointment only)',
        price: '£120'
    }],
    feeding: [{
        id: 0,
        service: 'In clinic sessions (90 mins)',
        price: '£80'
    }, {
        id: 1,
        service: 'Home visit (90 mins)',
        price: '£120'
    }]
}

export const faq = [{
    id: 0,
    question: "Is Osteopathy Safe?",
    answer: <><p>Yes it is. Our osteopaths are expert primary healthcare practitioners and your safety is our primary concern. In common with medical practitioners and dentists, all osteopaths are registered by law under the Osteopathic Act 1993 and the title ‘osteopath’ is protected by legislation. The profession is also statutorily regulated through the General Osteopathic Council (GOsC) so It is against the law to call yourself an osteopath unless you are qualified and registered with the GOsC.</p><p>As with all medical intervention, osteopathy does have risks associated it. We will explain the possible risks of treatment and discuss any worries you may have before beginning your treatment programme, but you can be reassured by the knowledge that osteopathic care has helped millions of people over the years. Osteopathy is very safe. It is estimated that between 1 in 50,000 and 1 in 100,000 patients will suffer a reaction to osteopathic treatment that is serious enough to require further medical treatment or does not resolve within 48 hours.</p></>
}, {
    id: 1,
    question: "Will the Treatment Be Painful?",
    answer: <><p>All our Osteopaths work very hard to make treatment as painless as possible, but like other medical treatments some patients may experience some discomfort during or after treatment. Your osteopath will warn you if they think that the technique that they are about to use is likely to be uncomfortable and will stop if you tell them that you are feeling too much pain.</p><p>Following treatment about half of patients report some mild soreness in the area of their body that was treated, this can usually be relieved with an over the counter pain killer and will go away within 48 hours. In the very unlikely event that you experience serious or unusual symptoms after treatment you should contact your osteopath straight away for further advice.</p></>
}, {
    id: 2,
    question: "What Training Has an Osteopath Had?",
    answer: <><p>Osteopathy was the first complementary healthcare profession to be given statutory regulation by the government, and our osteopaths are expert primary healthcare professionals. The minimum qualification for an osteopath is completion of a four or five year degree, which includes at least 1000 hours of supervised clinical practice. Many osteopaths also study for masters degrees. They must then continue to update and expand their knowledge by logging a minimum of 30 hours per year of continuing professional development. All our Osteopaths continue to explore academic and clinical interests with additional specialist training.</p><p>GOsC can remove an osteopath from the register if they fail to maintain a strict code of professional practice. You can check whether an osteopath is registered by visiting the GOsC website.. The GOsC protects patients by regulating and setting standards for osteopathic education, practise and conduct.</p></>
}, {
    id: 3,
    question: "Do I need to Consult My GP Before Booking an Appointment?",
    answer: <p>There is no need to consult your GP before visiting us, although you may choose to do so. Osteopaths are classed as ‘primary care physicians’ – this means you can consult us without approaching your GP beforehand. While we don’t have the authority to prescribe drugs or painkillers, we are able to provide off-work certificates/sick notes if necessary. If in any doubt please just contact us and we would be delighted to discuss and answer your questions on the telephone.</p>
}, {
    id: 4,
    question: "How Many Treatments Will I Need?",
    answer: <p>This will depend on your individual problem, its severity and the duration of symptoms. Every patient reacts differently, but we will be able to advise you on your likely recovery time. In most cases you will feel an improvement after the first treatment. In general though, the longer you’ve had the problem or pain, the longer it will take to get it sorted. Your commitment to the suggested treatment programme, including home exercises and any lifestyle alterations will also affect your progress and your long term benefits from osteopathic care.</p>
}, {
    id: 5,
    question: "Can I Have Treatment During Pregnancy?",
    answer: <><p>Osteopathic treatment is gentle and safe throughout your pregnancy although we advise against undergoing some techniques in the first 12 weeks. In fact, pregnancy is a good time to visit us, since your body is changing so much and so quickly. We can ease any restrictions and strains quite simply, as your ligaments are softer than usual at this time in your life. The treatment we provide can also help to make labour easier, through the balancing effect it has on the joints and ligaments.</p><p>If in any doubt please just contact us and we would be delighted to discuss and answer your questions on the telephone.</p></>
}, {
    id: 6,
    question: "Is Osteopathy Suitable for Children and Babies?",
    answer: <p>Yes – everyone can benefit from our treatment, no matter what their age. The techniques we use on children and infants are very different to those we use on adults: because children are very delicate, we adapt our treatment to suit the needs of the young body. We regularly treat babies and children effectively for problems such as colic, sleep and feeding problems, hyperactivity, asthma and breathing difficulties and digestive problems. If in any doubt please just contact us and we would be delighted to discuss and answer your questions on the telephone.</p>
}, {
    id: 7,
    question: "Can I Use My Healthcare Insurance?",
    answer: <p>Yes. Our osteopaths are registered for treatment with a number insurance providers, however you should check with your insurance provider for details. Sometimes you will need a referral letter from your doctor for the treatment to be covered. We prefer to ask you to pay for each appointment during the course of treatment, and we will be happy to issue you a receipt for you to send to your insurance provider for reimbursement.</p>
}]