import * as React from 'react'
import { Helmet } from 'react-helmet'
import Layout from '../components/Layout/Layout'
import Banner from '../components/Banner/Banner'
import Button from '../components/Button/Button'
import Text from '../components/Text/Text'
import Underline from '../components/Underline/Underline'
import Title from '../components/Title/Title'
import LinkGrid from '../components/LinkGrid/LinkGrid'
import Testimonial from '../components/Testimonial/Testimonial'
import * as ConditionsStyles from './conditions-we-treat.module.css'
import { conditions } from '../util/data'

function Conditions() {
    return (
        <Layout>
            <Helmet>
                <title>Conditions we treat | Brixworth Osteopathic Clinic</title>
            </Helmet>
            <section className={ConditionsStyles.container}>
                <Title>
                    <h1>Conditions We Treat</h1>
                    <h2>There are a wide range of conditions that osteopaths can help to treat. Find out more about how we might be able to help you.</h2>
                </Title>
            </section>
            <section className="condense">
                <Text>
                    <Underline>
                        <h2>A Whole Body Approach</h2>
                    </Underline>
                    <p>Osteopaths spend four to five years training to understand the relationship between the body’s organs, joints and muscles and are experienced in treating disorders of the musculoskeletal structures throughout the whole body. Osteopathy is a person centred approach that strives to provide the optimum conditions for health by working with your body’s own healing mechanisms to achieve results.</p>
                    <p>Osteopaths see people of all ages, including pregnant women and elite athletes.

The health risks associated with having osteopathic treatment are extremely low, if you have any concerns about the safety of your treatment your osteopath will be happy to discuss these with you.</p>
                </Text>
            </section>
            <section className="condense">
                <Text>
                    <Underline>
                        <h2>What Osteopathy Can Help With</h2>
                    </Underline>
                    <p>Although osteopaths are well known for treating back pain, the practice of osteopathy can help relieve the symptoms of a wide range of conditions. This includes joint pain, neuromuscular conditions, digestive conditions, headaches, sleep problems and more.</p>
                    <p>Find out more about how osteopathy can help with any of the following conditions:</p>
                </Text>
                <LinkGrid items={conditions} />
            </section>
            <section>
                <Text>
                    <Underline>
                        <h2>Referral to Other Health Practitioners</h2>
                    </Underline>
                    <p>As a primary healthcare professional your osteopath, in addition to their osteopathic skills, have been trained to undertake detailed medical histories and a comprehensive range of clinical examinations in an effort to diagnose the cause of your symptoms.</p>
                    <p>It is due to this extensive training that they are able to determine if you may need to be referred on for further tests to determine an accurate diagnosis, or if your condition may require the intervention of another health professional. When this happens your osteopath can write to your GP outlining their findings and requesting further investigations or referral to an appropriate consultant.</p>
                </Text>
            </section>
            <Testimonial name="Wendy" age="68">
                <p>I was so anxious that the aches and pains I was experiencing would prevent me enjoying my retirement and spending time with my new grandchildren.</p>
                <p>Gordon was so understanding - he listened to me and refused to accept that I had to just "put up with it". I exercise regularly now and although the aches come and go, I feel able to enjoy my life to the full. Thank you and see you soon!</p>
            </Testimonial>
            <section className={ConditionsStyles.banner}>
                <Banner>
                    <h2>Make an appointment with us.</h2>
                    <Button external={true} type="secondaryOutline" to="https://eubookings.nookal.com/bookings/location/GGNJC">Book online today</Button>
                </Banner>
            </section>
        </Layout>
    )
}

export default Conditions